module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/bi-medien-google-news-sitemap.xml":["X-Robots-Tag: noindex"]},"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":false,"withAvif":true,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImage":true,"maxWidth":832.5,"showCaptions":true,"wrapperStyle":"min-width:50px;margin: 1.5rem auto","markdownCaptions":false,"quality":50,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B_I MEDIEN","short_name":"B_I MEDIEN","start_url":"/","background_color":"#E5EDF0","display":"standalone","icon":"src/images/b-i-favicon.png","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d279aab45c22b24d29c531af0d31715e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8HCMZG","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
